<template>
  <div id="checkOrder" class="container-fluid m-0 p-0">
    <div class="row m-0 p-3 justify-content-center">
      <div class="col-12 col-lg-12 m-0 p-0 shadow   bg-white rounded">
        <div class="row m-0 p-4">
          <div class="col-12 mx-0 mb-3 mb-xl-0">
            <p class="font-1-2s font-600">Check Orders</p>
          </div>
          <div class="col-12 col-lg-6">
            <div class="input-group input-group-sm mb-3">
              <div class="input-group-prepend input-group-sm">
                <input type="date" class="form-control" v-model="searchDate" />
                <select class="custom-select" v-model="mode">
                  <option v-for="(e, i) in select_mode" :key="i" :value="e.key">
                    {{ e.name }}
                  </option>
                </select>
              </div>
              <input
                type="search"
                class="form-control"
                placeholder="ค้นหา / Seach"
                v-model="find"
                v-on:keyup.enter="searchValueFromObject()"
              />
              <button
                type="button"
                class="btn bt-main btn-sm mx-1 px-4"
                @click="searchValueFromObject()"
              >
                <span class="mx-1" v-html="$svg_icon.search"></span>ค้นหา
              </button>
              <!-- export csv btn -->
              <button
                type="button"
                class="btn bt-BNB btn-sm mx-1 px-4"
                @click="exportCSV()"
              >
                นำข้อมูลออก
              </button>
            </div>
          </div>
        </div>
        <div class="row m-0 pl-4 pb-4">
          <div class="col-12 mx-0 mb-3 mb-xl-0">
            <p class="px-0 font-700 w-100 font-0-9s" style="display: flex">
              จำนวน &nbsp;
              <span class="text-main">
                {{ new Intl.NumberFormat().format(totalRows) }}
              </span>
              <span class="ml-2">รายการ</span>
            </p>
            <div v-if="isLoading" class="col-12 col-xl-12 m-0 p-0 text-center">
              <div class="p-xl-5 m-xl-5">
                <b-spinner variant="info" small type="grow"></b-spinner>
                <b-spinner variant="info" small type="grow"></b-spinner>
                <b-spinner variant="info" small type="grow"></b-spinner>
              </div>
            </div>
            <div v-else>
              <b-table
                hover
                outlined
                show-empty
                class="form-control-sm font-0-7s"
                :items="items"
                :fields="fields"
                head-variant="light"
                :current-page="page_num"
                :per-page="page_size"
              >
              </b-table>
            </div>

            <div class="col-12">
              <div class="row justify-content-end">
                <div class="col-6 col-xl-2 input-group-prepend input-group-sm">
                  <select class="custom-select" v-model="page_size">
                    <option
                      v-for="(e, i) in page_size_list"
                      :key="i"
                      :value="e"
                    >
                      {{ e }}
                    </option>
                  </select>
                </div>
                <div class="col-6 col-xl-3">
                  <b-pagination
                    v-model="page_num"
                    :total-rows="totalRows"
                    :per-page="page_size"
                    align="fill"
                    size="sm"
                    class="my-0"
                  ></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CheckOrders",
  data() {
    return {
      mode: "ORDERNO",
      find: "",
      searchDate: new Date().toISOString().substr(0, 10),
      isLoading: false,
      items: [],
      itemlist: [],
      totalRows: 0,
      page_size: 25,
      page_size_list: [25, 50, 100],
      page_num: 1,
      select_mode: [
        { key: "ORDERNO", name: "Order No." },
        { key: "GENTICKET", name: "Post" },
        { key: "WEB", name: "Web" },
        { key: "POSREF", name: "Ticket No." },
        { key: "SALSOURCE", name: "Sale Source" },
      ],
      fields: [
        {
          key: "ORDERNO",
          label: "Order No.",
          class: "text-center",
          sortable: true,
        },
        {
          key: "ORDERDATE",
          label: "Order Date",
          class: "text-center",
          sortable: true,
        },
        {
          key: "GENTICKET",
          label: "Post",
          class: "text-center",
          sortable: true,
        },
        {
          key: "POSTDATE",
          label: "Post Date",
          class: "text-center",
          sortable: true,
        },
        { key: "WEB", label: "Web", class: "text-center", sortable: true },
        {
          key: "GENTICKET",
          label: "Gen Ticket",
          class: "text-center",
          sortable: true,
        },
        {
          key: "GENTICKETDATE",
          label: "Gen Ticket Date",
          class: "text-center",
          sortable: true,
        },
        {
          key: "POSREF",
          label: "Ticket No.",
          class: "text-center",
          sortable: true,
        },
        {
          key: "SALSOURCE",
          label: "Sale Source",
          class: "text-center",
          sortable: true,
        },
      ],
    };
  },
  async mounted() {
    this.isLoading = true;
    await this.initTable();
    this.isLoading = false;
  },
  methods: {
    async initTable() {
      try {
        const payload = {
          searchDate: this.searchDate,
        };

        let getAPI = await this.$serviceAPI.call_API(
          "post",
          `market-place/getOrders`,
          payload,
          1
        );
        this.items = getAPI.data;
        this.itemlist = getAPI.data;
        this.totalRows = this.items.length;
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },

    async exportCSV() {
      try {
        this.$serviceMain.JsonToExcat(
          this.items,
          `order-${this.searchDate}.xlsx`
        );
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async searchValueFromObject() {
      try {
        this.items = this.itemlist.filter((item) => {
          return (
            item[this.mode] !== null &&
            item[this.mode].toLowerCase().includes(this.find.toLowerCase())
          );
        });
        this.totalRows = this.items.length;
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },

    async unSearchValueFromObject() {
      this.items = this.itemlist;
      this.totalRows = this.items.length;
    },
  },
  watch: {
    find: function(v) {
      if (v === "") {
        this.unSearchValueFromObject();
      }
    },
    searchDate: function(v) {
      if (v) {
        this.initTable();
      }
    },
  },
};
</script>
